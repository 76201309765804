import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tab/home',
    pathMatch: 'full'
  },
  {
    path: 'tab',
    loadChildren: () => import( './tab/tab.module' ).then( m => m.TabPageModule )
  },
  {
    path: 'login',
    loadChildren: () => import( './login/login.module' ).then( m => m.LoginPageModule )
  },
  {
    path: 'signup',
    loadChildren: () => import( './signup/signup.module' ).then( m => m.SignupPageModule )
  },
  {
    path: 'create-soup',
    loadChildren: () => import( './soup/create/create.module' ).then( m => m.CreatePageModule )
  },
  {
    path: 'soup/detail/:id',
    loadChildren: () => import( './soup/detail/detail.module' ).then( m => m.DetailPageModule )
  },
  {
    path: 'create-recipe',
    loadChildren: () => import('./recipe/create/create.module').then( m => m.CreatePageModule)
  },
  {
    path: 'recipe/detail/:id',
    loadChildren: () => import('./recipe/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'myrecipes',
    loadChildren: () => import('./recipe/myrecipes/myrecipes.module').then( m => m.MyrecipesPageModule)
  },


  // {
  //   path: 'profile',
  //   loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  // },
  // {
  //   path: 'mysoups',
  //   loadChildren: () => import('./mysoups/mysoups.module').then( m => m.MysoupsPageModule)
  // },
  // {
  //   path: 'myrecipes',
  //   loadChildren: () => import('./myrecipes/myrecipes.module').then( m => m.MyrecipesPageModule)
  // },
  // {
  //   path: 'myfavourites',
  //   loadChildren: () => import('./myfavourites/myfavourites.module').then( m => m.MyfavouritesPageModule)
  // }



];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes, { preloadingStrategy: PreloadAllModules } )
  ],
  exports: [RouterModule]
} )
export class AppRoutingModule { }
