// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  drupalLink: "https://dev-myfamilyrecipe.pantheonsite.io",
  socialSignInPass:"hD1oZ6yS1vR2pW3g",
  firebaseEmail:"support@myfamilysoup.com",
  firebasePass:"cJfpClWJ2HUo7xgf",
  firebaseConfig: {
    apiKey: "AIzaSyCzB8UNUyenPc9QJbL9r9u5a3HRDf3x_-k",
    authDomain: "myfamilysoup.firebaseapp.com",
    databaseURL: "https://myfamilysoup.firebaseio.com",
    projectId: "myfamilysoup",
    storageBucket: "myfamilysoup.appspot.com",
    messagingSenderId: "1053115127357",
    appId: "1:1053115127357:web:b40c0a06e448f86afffc54",
    measurementId: "G-W76L2VS5J7"
  }
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
